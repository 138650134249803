<template>
  <div class="">
    <section v-if="info" id="info" class="bg-dwaya-4 inset-0 absolute z-50 overflow-y-scroll">
      <div class="max-w-8xl mx-auto py-12 lg:py-20 px-6 md:px-8 lg:px-12 xl:px-0">
        <button @click="toggle" class="w-6 h-6 rounded-full left-0 focus:outline-none">
          <svg :class="`${$i18n.locale}` == 'ar' ? '' : 'transform rotate-180'" version="1.1" id="Capa_1" x="0px" y="0px"
            viewBox="0 0 490.667 490.667" style="enable-background:new 0 0 490.667 490.667;" xml:space="preserve">
          <path style="fill:white;" d="M245.333,0C109.839,0,0,109.839,0,245.333s109.839,245.333,245.333,245.333
            s245.333-109.839,245.333-245.333C490.514,109.903,380.764,0.153,245.333,0z"/>
          <path style="fill:#2e3383;" d="M290.133,104.533c-19.139-18.289-49.277-18.289-68.416,0c-18.893,18.881-18.902,49.503-0.021,68.395
            c0.007,0.007,0.014,0.014,0.021,0.021l19.2,19.2H117.333C87.878,192.149,64,216.027,64,245.483
            c0,29.455,23.878,53.333,53.333,53.333h123.584l-19.2,19.2c-17.522,20.175-15.371,50.734,4.804,68.257
            c18.259,15.858,45.423,15.799,63.612-0.139l118.251-118.251c12.492-12.496,12.492-32.752,0-45.248L290.133,104.533z"/>
          </svg>
        </button>
        <div class="mt-6 md:mt-10 lg:mt-16 lg:flex">
          <div class="lg:w-3/12 text-dwaya-3 text-lg">
            <div class="font-bold text-2xl md:mb-2">+ {{ $t("Dwaya.project_info.project_info") }}</div>
            <br>
            {{ $t("Dwaya.project_info.work.1") }} /
            <br class="hidden lg:block">
            {{ $t("Dwaya.project_info.work.2") }} /
            <br class="hidden lg:block">
            {{ $t("Dwaya.project_info.work.3") }} /
            <br class="hidden lg:block">
            {{ $t("Dwaya.project_info.work.4") }} /
            <br class="hidden lg:block">
            {{ $t("Dwaya.project_info.work.5") }} /
          </div>
          <div class="lg:w-9/12 text-dwaya-3 mt-6">
            <p class="text-4xl font-bold">{{ $t("Dwaya.project_info.description.title") }}</p>

            <div class="">
              <p class="mt-6 text-justify leading-7 text-lg">
                {{ $t("Dwaya.project_info.description.content_1") }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section id="hero" class="w-full  h-screen relative bg-center">
      <div class="flex items-center h-full absolute right-0 z-10">
          <button @click="toggle" class="bg-dwaya-1 w-6 md:w-10 py-1 text-white font-extrabold text-lg md:text-3xl rounded-l-xl flex justify-center items-center focus:outline-none">
              +
          </button>
      </div>
      <div class="flex justify-center items-center h-full bg-white relative">
        <img src="../assets/images/dwaya/Asset 1@4x.png" alt="dwaya" class="w-64 h-64">
        <!-- <a href="#video" class="bg-5g-video w-16 lg:w-32 h-10 lg:h-20 absolute z-10 rounded-xl flex justify-center items-center transform duration-700 hover:scale-105 focus:outline-none">
          <svg class="w-6 lg:w-12 " id="Layer_1_1_" style="enable-background:new 0 0 16 16;" version="1.1" viewBox="0 0 16 16" xml:space="preserve" xmlns="http://www.w3.org/2000/svg" fill="#fff" xmlns:xlink="http://www.w3.org/1999/xlink"><polygon points="1,1 1,15 15,8 "/></svg>
        </a> -->
      </div>
    </section>

    <section class="bg-dwaya-2 pt-16">
      <div class="container mx-auto px-6 xl:px-12">
        <p class="text-dwaya-3 text-3xl md:text-5xl xl:text-6xl font-black uppercase text-center max-w-5xl mx-auto">
                 {{ $t("Dwaya.section1") }}
        </p>

        <img src="../assets/images/dwaya/DWAYA_GIF_WEBSITE.gif" alt="" class="mx-auto mt-12 w-full">

        <p class="mt-16 text-2xl">
                 {{ $t("Dwaya.section2") }}
        </p>
      </div>

      <img src="../assets/images/dwaya/Asset 2.png" alt="">
    </section>

    <section class="md:flex justify-between items-end container mx-auto px-6 xl:px-12 mt-8  md:-mt-10 lg:-mt-32">

      <div class="md:w-1/2 md:pr-8">
        <p class="text-dwaya-3 text-5xl lg:text-6xl xl:text-8xl font-black">
                          {{ $t("Dwaya.section3") }}

        </p>
        <p class="text-dwaya-3 text-2xl mt-6">
                 {{ $t("Dwaya.section4") }}
        </p>
      </div>
      <div class="md:w-1/2 md:pl-8 mt-6 md:mt-0">
        <img src="../assets/images/dwaya/DEWAYA_2020_POSTER@0.5x.png" alt="">
      </div>
    </section>

    <section class="mt-20">
      <img src="../assets/images/dwaya/Story.png" alt="">
    </section>

    <section class="bg-dwaya-4 py-24 lg:py-40">
      <div class="container mx-auto grid md:grid-cols-3 gap-8 lg:gap-12 xl:gap-16 px-6 xl:px-12">
        <img src="../assets/images/dwaya/BASHIR.jpg" alt="" class="w-full h-64 lg:h-70 object-cover shadow-2xl">
        <img src="../assets/images/dwaya/HREZ.jpg" alt="" class="w-full h-64 lg:h-70 object-cover shadow-2xl">
        <img src="../assets/images/dwaya/RAWAN.jpg" alt="" class="w-full h-64 lg:h-70 object-cover shadow-2xl">
      </div>
    </section>

     <section class="">
      <img src="../assets/images/dwaya/Story.png" alt="">
    </section>

    <section class="my-24 container mx-auto px-6 xl:px-12">
      <div class="grid md:grid-cols-3 gap-8 lg:gap-12 xl:gap-16">
        <img src="../assets/images/dwaya/BARUDI_GIF.gif" alt="">
        <img src="../assets/images/dwaya/ASHRF_GIF.gif" alt="">
        <img src="../assets/images/dwaya/RAWAN-GIF.gif" alt="">
      </div>

    <p class="text-dwaya-3 text-3xl md:text-5xl xl:text-6xl font-black uppercase text-center max-w-5xl mx-auto">
                 {{ $t("Dwaya.section5") }}
        </p>
        <p class="text-center text-2xl text-dwaya-3 mt-24">
                        {{ $t("Dwaya.section6") }}

      </p>
    </section>

    <div class="text-xl md:text-3xl flex justify-center text-white bg-black pt-4 linkwork fontRg -mt-1 -mb-1 z-10">
      <router-link :to="`/${$i18n.locale}/AbnaYousef`">
        {{ $t("Next-Project") }}
      </router-link>
    </div>
  </div>
</template>

<script>
export default {
  data() {
      return {
        info : false
      }
  },
  mounted() {
    // scrollTo(0, 0)
  },
  methods:{
    toggle(){
      if(this.info == true){
        this.info = false
        document.body.style.overflow = ""
      }else{
        this.info = true
        scrollTo(0, 0)
        document.body.style.overflow = "hidden"
      }
      
    }
  }
}
</script>

